:root{
  --primary-color:#005BDD;
  --secondary-color:#E9F2FF;
  --danger-color:#FEEBEF;
  --success-color:#EBFEF7;
  --loading-color:#E9F2FF;
  --bg-card-color:#F4F4FB;
  --table-accent-color:#F4F4FB;
  --border-radius: 8px;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
}

.m-logo{
  margin-top: 115px !important;
}

.h-100{
  min-height: 100vh;
}

.h-90{
  min-height: 90vh;
}

.illustration-img{
  position: relative;
  background-color: var(--primary-color);
}

.illustration-img > img{
  position: absolute;
  bottom: 0;
  right: -50px;
}

.illustration-form {
  background-color: #E9F2FF;
}

.text-bold{
  font-weight:700;
}
.text-48{
  font-size: 3rem;
}

.text-32{
  font-size: 2rem;
}

.text-meduim{
  font-size: 1.125rem;
}

.text-16{
  font-size: 1rem;
}

.text-small{
  font-size: 0.875rem;
}

.text-ultra-small{
  font-size: 0.75rem;
}
.line-height{
  line-height: 180%;
}

.text-primary{
  color: var(--primary-color) !important;
}
.link{
  color: inherit;
  text-decoration: none;
}

.nav-item.link-border{
  /*width: 80%;
  border: 1px solid gray;
  border-radius: var(--border-radius);*/
  padding: 0;
}

.link-border-primary{
  border-color: var(--primary-color) !important;
}

.text-deco{
  text-decoration: underline;
}

.nav{
  margin-left: 3%;
}

.nav-link > span.wd-0, span.wd-80{
  border-radius: var(--border-radius);
  max-height: 40px;
  /*display: inline-block;
  padding: 10px 0;
  padding-left: 10px;
  margin-left: -10px;*/
}


.active > span.wd-0, span.wd-80{
  border-radius: var(--border-radius);
  display: inline-block;
  /*padding: 10px 0;
  padding-left: 10px;
  margin-left: -10px;*/
}

span.wd-0 {
  background-color: var(--secondary-color);
  max-width: 45px;
  padding: 10px 13px;
  margin-left: -5px;
}

span.wd-80 {
  width: 100%;
  /*padding: 0;
  margin: 0;*/
}

.active > span.wd-0 {
  background-color: var(--primary-color);
  padding: 10px 13px;
  margin-left: -5px;
}

.active > span.wd-80 {
  background-color: var(--primary-color);
  width: 100%;
  color: #fff;
  padding: 10px 0;
  padding-left: 10px;
  margin-left: -10px;
}

.navbar-toggler{
  background-color: var(--secondary-color);
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-toggler.navbar-toggler {
  border-radius: var(--border-radius);
  border-color: var(--secondary-color);
} 

.btn-height{
  height: 116px;
  cursor: pointer;
}

.btn-primary,.btn-secondary,.btn-danger, .btn-gray{
  background-color: var(--primary-color);
  font-weight: 600;
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius) !important;
  height: 40px;
  font-size: 1rem;
}

.btn-secondary,.btn-secondary:hover{
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.btn-danger, .bg-danger{
  background-color: var(--danger-color);
  border: 1px solid var(--danger-color);
  color: black;
}

.btn-gray {
  background: #F4F4F4; 
  color: black;
  border: 1px solid transparent;
}

.btn-gray:hover {
  background: #F4F4F4; 
  
}

.bg-secondary{
  background-color: var(--secondary-color) !important;
  border: 1px solid var(--primary-color);
  color: black;
}
.bg-warning{
  background-color: #FEF3EB !important;
  border: 1px solid #F69C33;
  color: black;
}

.bg-danger{
  background-color: var(--danger-color) !important;
  border: 1px solid red;
  color: black;
}

.bg-success{
  background-color: var(--success-color) !important;
  border: 1px solid #33F6B0;
  color: black;
}

.bg-loading{
  background-color: var(--loading-color) !important;
  border: 1px solid #005BDD;
  color: black;
}

.border-radius{
  border-radius: var(--border-radius);
}

.border-radius-2{
  border-radius: 16px;
}

input, select{
  border-radius: var(--border-radius) !important;
  height: 50px;
}

.no-height{
  height: fit-content;
}

h1{
  font-size: 2rem;
}

.search{
  height: 45px;
  width: 50vw;
  max-width: 500px;
}

.table-striped > tbody > tr:nth-of-type(2n+1) > * {
  --bs-table-accent-bg: var(--table-accent-color);
  color: inherit;
}

thead{
  background-color: var(--primary-color);
  color: #fff;
  height: 56px;
}

.form-label{
  font-weight: bold;
}

.bottom-3 {
  bottom: 3.5vh;
}

.end-1 {
  right: 0.5vw;
}

.eye-position{
  bottom: 35%;
  right: 6%;
}

.circle {
  width: 100px;
  height: 100px;
  border: 1px solid transparent;
  border-radius: 50%;
}

.v-card{
  background-color: var(--bg-card-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius);
}

/**** loading css****/
.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 3px solid #005BDD;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #005BDD transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/**** end loading css****/

.react-datepicker__day--highlighted-custom-1, .react-datepicker__day--highlighted-custom-2{
  color: red !important;
  
}


@keyframes ldio-s71ku63tgz {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-s71ku63tgz div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: ldio-s71ku63tgz linear 1s infinite;
  background: var(--primary-color);
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}.ldio-s71ku63tgz div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: var(--primary-color);
}.ldio-s71ku63tgz div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: var(--primary-color);
}.ldio-s71ku63tgz div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: var(--primary-color);
}.ldio-s71ku63tgz div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: var(--primary-color);
}.ldio-s71ku63tgz div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: var(--primary-color);
}.ldio-s71ku63tgz div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: var(--primary-color);
}.ldio-s71ku63tgz div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: var(--primary-color);
}.ldio-s71ku63tgz div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: var(--primary-color);
}.ldio-s71ku63tgz div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: var(--primary-color);
}.ldio-s71ku63tgz div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: var(--primary-color);
}.ldio-s71ku63tgz div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: var(--primary-color);
}.ldio-s71ku63tgz div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: var(--primary-color);
}
.loadingio-spinner-spinner-g7josdrmxi {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-s71ku63tgz {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-s71ku63tgz div { box-sizing: content-box; }

